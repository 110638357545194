
import { Component, Vue, Prop } from 'vue-property-decorator';
import type Day from '@/modules/common/types/day.type';
import ClusterRatesDayPopup from '../components/cluster-rates-day.popup.vue';
import ClusterAllChannelsDayPopup from '../components/cluster-all-channels-day.popup.vue';

@Component({
    components: { ClusterRatesDayPopup, ClusterAllChannelsDayPopup },
})
export default class ClusterRatesDayModal extends Vue {
    @Prop({ type: Number })
    day!: Day;

    get isAllChannelsMode() {
        return this.$route.name && this.$route.name.includes('di-lite');
    }
}
